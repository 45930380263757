<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center vh-100"
  >
    <h1 class="mb-2 text-dark">Acesso remoto</h1>
    <p class="text-center">Aplique seu conhecimento remotamente na MVI 9000.</p>
    <iframe
      :src="'https://luciano-cognisense.github.io/poc03/'"
      title="Streaming Senai"
      allowfullscreen
    >
    </iframe>
    <p class="mt-3 text-center">
      Tempo de acesso restante: {{ timeLeft }} segundos
    </p>
    <a @click="goBack(false)" class="btn btn-outline-primary btn-lg mt-3"
      >Voltar</a
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StreamingSenai",
  props: {
    userHash: String,
  },
  data() {
    return {
      timeLeft: 60,
      interval: null,
      timeout: null,
    };
  },
  methods: {
    goBack(timeExpired) {
      this.endStreaming()
        .then(() => {
          this.$emit("go-back", timeExpired);
        })
        .catch((error) => {
          console.error("Erro ao finalizar exercício remoto:", error);
          this.$emit("go-back", timeExpired);
        });
    },
    updateTimer() {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1;
      } else {
        this.goBack("Tempo de acesso ao exercício remoto expirado.");
      }
    },
    startStreaming() {
      const url = `https://api-ellevo-ia-hml1.hml.sesisenai.org.br/ai/lab-digital/${this.userHash}/iniciar-exercicio-remoto`;
      const data = {
        id_usuario: this.userHash,
      };
      axios
        .post(url, data)
        .then((response) => {
          if (response.status === 400) {
            this.goBack("Exercício remoto em uso");
          }
        })
        .catch((error) => {
          console.error("Erro ao iniciar exercício remoto:", error);
          this.goBack("Exercício remoto em uso.");
        });
    },
    endStreaming() {
      const url = `https://api-ellevo-ia-hml1.hml.sesisenai.org.br/ai/lab-digital/${this.userHash}/finalizar-exercicio-remoto`;
      return axios
        .put(url)
        .then((response) => {
          console.log(
            "Exercício remoto finalizado com sucesso:",
            response.data
          );
        })
        .catch((error) => {
          console.error("Erro ao finalizar exercício remoto:", error);
          throw error;
        });
    },
  },
  mounted() {
    this.interval = setInterval(this.updateTimer, 1000);

    this.timeout = setTimeout(() => {
      this.goBack("Tempo de acesso ao exercício remoto expirado.");
    }, 60000);

    this.startStreaming();
  },
  beforeUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  },
};
</script>

<style scoped></style>
