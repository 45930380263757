<template>
  <div
    class="mx-3 mx-md-0 d-flex flex-column align-items-center justify-content-center vh-100"
  >
    <div v-if="active === 'home'">
      <h1 class="mb-2 text-dark text-center">
        Bem-vindo(a) ao Laboratório Digital
      </h1>
      <p class="text-center">Inicie sua jornada de aprendizado.</p>
      <input
        type="text"
        v-model="name"
        placeholder="Digite seu nome"
        class="form-control form-control-lg mb-2 text-center opacity-75"
      />
      <div class="text-center text-dark p-3 rounded">
        <div>
          <a
            :disabled="!this.name"
            @click="openLaboratorio"
            :class="!this.name ? 'disabled' : ''"
            class="btn btn-success btn-lg m-2"
            >Iniciar exercícios</a
          >
          <a
            :disabled="!this.name"
            @click="checkExerciseCompletion"
            :class="!this.name ? 'disabled' : ''"
            class="btn btn-outline-success btn-lg m-2"
            >Acesso remoto</a
          >
        </div>
      </div>
      <p v-if="streamingError" class="text-center text-danger">
        {{ streamingError }}
      </p>
    </div>
    <div v-if="active === 'laboratorio'">
      <Laboratorio :userHash="userHash" @go-back="close" />
    </div>
    <div v-if="active === 'streaming'">
      <Streaming :userHash="userHash" @go-back="close" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Laboratorio from "./LaboratorioSenai.vue";
import Streaming from "./StreamingSenai.vue";

export default {
  name: "HomePage",
  components: {
    Laboratorio,
    Streaming,
  },
  watch: {
    name() {
      if (this.name) {
        this.saveUserName();
      }
    },
  },
  data() {
    return {
      active: "home",
      content: "",
      streamingError: "",
      name: "",
      userHash: "",
    };
  },
  mounted() {
    const userName = sessionStorage.getItem("userName");

    if (userName) {
      this.name = userName;
    }
  },
  methods: {
    openLaboratorio() {
      if(!this.userHash) {
        this.userHash = this.saveUserHash()
      }
      this.active = "laboratorio";
    },
    checkExerciseCompletion() { 
      if(!this.userHash) {
        this.userHash = this.saveUserHash()
      }

      const url = `https://api-ellevo-ia-hml1.hml.sesisenai.org.br/ai/lab-digital/${this.userHash}/finalizou-exercicio-gemeo-digital`;
      axios
        .get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          if (response.data) {
            this.checkStreamingAvailability();
          } else {
            this.streamingError =
              "Você precisa concluir o exercício do laboratório digital antes de acessar o streaming.";
          }
        })
        .catch((error) => {
          console.error(
            "Erro ao verificar conclusão do exercício do gêmeo digital:",
            error
          );
          this.streamingError = "Erro ao verificar conclusão do exercício.";
        });
    },
    checkStreamingAvailability() {
      const url =
        "https://api-ellevo-ia-hml1.hml.sesisenai.org.br/ai/lab-digital/exercicio-remoto-disponivel";
      axios
        .get(url)
        .then((response) => {
          if (response.data) {
            this.openStreaming();
          } else {
            this.streamingError = "Exercício remoto em uso.";
          }
        })
        .catch((error) => {
          console.error(
            "Erro ao verificar disponibilidade do exercício remoto:",
            error
          );
          this.streamingError = "Erro ao verificar disponibilidade.";
        });
    },
    openStreaming() {
      this.active = "streaming";
    },
    close(error) {
      this.active = "home";
      this.streamingError = error;
    },
    saveUserHash() {
      const hash = this.generateHash(this.name);
      sessionStorage.setItem("userHash", hash);
      this.userHash = hash;
      return hash;
    },
    saveUserName() {
      sessionStorage.setItem("userName", this.name);
    },
    generateHash(value) {
      let hash = 0,
        i,
        chr;
      if (value.length === 0) return hash;
      for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
      return hash;
    },
  },
};
</script>

<style scoped></style>
