<template>
  <div>
  <div
    class="d-flex flex-column align-items-center justify-content-center vh-100"
  >
    <h1 class="mb-2 text-dark">Laboratório de Exercícios</h1>
    <p class="text-center">Realize os seguintes desafios para avançar.</p>
    <iframe
      :src="'https://guilhermeouriques.github.io/protolabdigital/?id_usuario=' + userHash"
      title="Laboratório Senai"
      allowfullscreen
    >
    </iframe>
    <a @click="goBack" class="btn btn-outline-primary btn-lg mt-3">Voltar</a>
  </div>
    <div class="chat-container">
      <Chat :userHash="userHash" />
    </div>
  </div>
</template>

<script>
import Chat from "./ChatSenai.vue";

export default {
  name: "LaboratorioSenai",
  props: {
    userHash: String,
  },
  components: {
    Chat,
  },
  methods: {
    goBack() {
      this.$emit("go-back", false);
    },
  },
};
</script>

<style scoped></style>
