<template>
  <div>
    <div
      class="chat-icon text-white d-flex align-items-center justify-content-center rounded-circle shadow pb-1"
      @click="toggleChat"
    >
      🤖
    </div>

    <div v-show="isChatOpen" class="chat-box bg-light p-3 mt-2">
      <button
        class="close-btn btn btn-light position-absolute"
        @click="toggleChat"
      >
        &times;
      </button>
      <iframe
        :src="
          'https://tutor-fep.sc.senai.br/?embed=true&id_usuario=' + userHash
        "
        width="100%"
        height="80vh"
        class="chat-iframe"
        title="Chat Senai"
        allowfullscreen
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatSenai",
  props: {
    userHash: String,
  },
  data() {
    return {
      isChatOpen: true,
    };
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
  },
};
</script>

<style scoped></style>
